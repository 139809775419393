<template>
  <v-container class="fill-height loginContainer" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" md="4" sm="8">
        <v-card class="elevation-12">
          <v-toolbar color="#ffffff" dark flat>
            <v-toolbar-title class="title">
              <img src="https://www.cambralleida.org/wp-content/uploads/2019/02/Cambra-Lleida-logotip.png">
              <h1 class="ml-5">Intranet autodiagnosi Cambra</h1>
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-alert v-if="error"
                     dense
                     outlined
                     type="error">
              E-Mail o Contraseña incorrectos
            </v-alert>
            <v-form @keyup.enter.native="submit">
              <v-text-field v-model="email"
                            color="#ba0c2f"
                            dark
                            label="Login"
                            name="login"
                            prepend-icon="mdi-account"
                            type="text"/>
              <v-text-field id="password"
                            v-model="password"
                            color="#ba0c2f"
                            label="Contraseña"
                            name="password"
                            prepend-icon="mdi-lock"
                            type="password"/>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn :loading="loading" color="#ba0c2f" v-on:click="submit">Entrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Login",
  data: () => ({
    email: '',
    password: '',
    loading: false,
    error: false
  }),
  methods: {
    submit() {
      this.error = false;
      this.loading = true;
      if(this.email === 'jquejido@cambralleida.com' && this.password === '*Cambra_cambra*'){
        this.$session.start();
        this.$session.set('token', 'asdf1234');
        this.$router.push('/');
        location.reload();
      }else{
        this.error = true;
      }

      this.loading = false;
    }
  }
}
</script>

<style scoped>


.loginContainer:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.title {
  display: contents;
}

.title h1 {
  font-size: 16pt;
  color: rgb(186, 12, 47);
}

.title img {
  width: 25%;
}
</style>